body {
	background: url(./festivals_and_events.png);
	background-size: cover;
}

.maintenance {
	display: flex;
	align-content: center;
	align-items: center;
	height: 100vh;
	text-align: center;
}

.maintenance__message {
	width: 66%;
	margin: auto;
	background: #fff;
	border-radius: 5px;
	padding: 16px 32px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.maintenance__img {
	width: 66%;
}

.App {
	text-align: center;
}

.App-header {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
